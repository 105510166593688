<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <router-link class="navbar-item" to="/">
                <img src="/images/icon.png" width="28" height="28">
            </router-link>

            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start">
                <router-link to="/" class="navbar-item">
                    Home
                </router-link>
                <router-link to="/product" class="navbar-item">
                    Product
                </router-link>
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">
                    More
                    </a>
                    <div class="navbar-dropdown">
                        <router-link class="navbar-item" to="/about">About</router-link>
                        <router-link class="navbar-item" to="/contact">Contact</router-link>
                        <hr class="navbar-divider">
                        <router-link class="navbar-item" to="/terms-and-conditions">Terms and Conditions</router-link>
                        <router-link class="navbar-item" to="/privacy-policy">Privacy Policy</router-link>
                    </div>
                </div>
            </div>

            <!-- <div class="navbar-end">
                
            </div> -->
        </div>
    </nav>
</template>
<script>
export default {
    name: 'Header',
    mounted() {

        document.addEventListener('DOMContentLoaded', () => {

            // Get all "navbar-burger" elements
            const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

            // Check if there are any navbar burgers
            if ($navbarBurgers.length > 0) {

                // Add a click event on each of them
                $navbarBurgers.forEach( el => {
                    el.addEventListener('click', () => {

                        // Get the target from the "data-target" attribute
                        const target = el.dataset.target;
                        const $target = document.getElementById(target);

                        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                        el.classList.toggle('is-active');
                        $target.classList.toggle('is-active');

                    });
                });
            }
        });
    }
}
</script>