import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/product',
		name: 'Product',
		component: () => import(/* webpackChunkName: "Product" */ '../views/Product.vue')
	},
	{
		path: '/terms-and-conditions',
		name: 'TermsAndConditions',
		component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../views/TermsAndConditions.vue')
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
	},
	{
		path: '/privacy-policy/korkhorkor',
		name: 'PrivacyPolicyKorkhorkor',
		component: () => import(/* webpackChunkName: "PrivacyPolicyKorkhorkor" */ '../views/privacy-policy/Korkhorkor.vue')
	},
	{
		path: '/privacy-policy/khaohoon',
		name: 'PrivacyPolicyKhaohoon',
		component: () => import(/* webpackChunkName: "PrivacyPolicyKhaohoon" */ '../views/privacy-policy/Khaohoon.vue')
	},
	{
		path: '/privacy-policy/stockintrend',
		name: 'PrivacyPolicyStockintrend',
		component: () => import(/* webpackChunkName: "PrivacyPolicyStockintrend" */ '../views/privacy-policy/Stockintrend.vue')
	},
	{
		path: '/privacy-policy/chordpleng',
		name: 'PrivacyPolicyChordpleng',
		component: () => import(/* webpackChunkName: "PrivacyPolicyChordpleng" */ '../views/privacy-policy/Chordpleng.vue')
	},
	{
		path: '/privacy-policy/khaohuy',
		name: 'PrivacyPolicyKhaohuy',
		component: () => import(/* webpackChunkName: "PrivacyPolicyKhaohuy" */ '../views/privacy-policy/Khaohuy.vue')
	},
	{
		path: '/privacy-policy/posttrack',
		name: 'PrivacyPolicyPosttrack',
		component: () => import(/* webpackChunkName: "PrivacyPolicyPosttrack" */ '../views/privacy-policy/Posttrack.vue')
	},
	{
		path: '/privacy-policy/siangnok',
		name: 'PrivacyPolicySiangnok',
		component: () => import(/* webpackChunkName: "PrivacyPolicySiangnok" */ '../views/privacy-policy/Siangnok.vue')
	},
	{
		path: '/privacy-policy/kerry',
		name: 'PrivacyPolicyKerry',
		component: () => import(/* webpackChunkName: "PrivacyPolicyKerry" */ '../views/privacy-policy/Kerry.vue')
	},
	{
		path: '/privacy-policy/rakathong',
		name: 'PrivacyPolicyRakathong',
		component: () => import(/* webpackChunkName: "PrivacyPolicyRakathong" */ '../views/privacy-policy/Rakathong.vue')
	},
	{
		path: '/privacy-policy/drivelicenseexamination',
		name: 'PrivacyPolicyDriveLicenseExamination',
		component: () => import(/* webpackChunkName: "PrivacyPolicyDriveLicenseExamination" */ '../views/privacy-policy/DriveLicenseExamination.vue')
	},
	{
		path: '/privacy-policy/bestexpress',
		name: 'PrivacyPolicyBestexpress',
		component: () => import(/* webpackChunkName: "PrivacyPolicyBestexpress" */ '../views/privacy-policy/Bestexpress.vue')
	},
	{
		path: '/privacy-policy/sianggeesorn',
		name: 'PrivacyPolicySianggeesorn',
		component: () => import(/* webpackChunkName: "PrivacyPolicySianggeesorn" */ '../views/privacy-policy/Sianggeesorn.vue')
	},
	{
		path: '/privacy-policy/calories',
		name: 'PrivacyPolicyCalories',
		component: () => import(/* webpackChunkName: "PrivacyPolicyCalories" */ '../views/privacy-policy/Calories.vue')
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
