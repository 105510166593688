<template>
	<div class="container">
		<Header></Header>
		<div class="page-view p-5">
			<router-view/>
		</div>
		<Footer></Footer>
	</div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
	name: 'App',
	components: {
		Header,
		Footer,
	},
}
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css";
.page-view {
	min-height: calc(100vh - 52px);
}
</style>
