<template>
    <footer class="footer has-text-centered">
        <div>
            <router-link to="/about">About</router-link> | 
            <router-link to="/contact">Contact</router-link> | 
            <router-link to="/terms-and-conditions">Terms and Conditions</router-link> | 
            <router-link to="/privacy-policy">Privacy Policy</router-link>
        </div>
        <div>
            <strong>Copyright © 2021 </strong> 
            <router-link to="/">boombeamstudio.com</router-link>.
            <span>All rights reserved. </span>
        </div>
    </footer>
</template>