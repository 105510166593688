<template>
	<div class="home is-flex is-justify-content-center is-align-items-center">
		<div>
			<img src="/images/icon-full.png">
			<div class="pb-3"></div>
			<p>We develop <router-link to="/product">applications</router-link> for a <strong>happy</strong> life.</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Home',
}
</script>
<style scoped>
.home {
	text-align: center;
	height: calc(100vh - 52px);
    width: 100%;
}
</style>
